<template>
  <div>
    <div class="title">我的班级</div>
    <div class="tab">
      <div :class="active == 1 ? 'active' : ''" @click="cut(1)">正在学习</div>
    </div>
    <humanface type="1" ref="chind"></humanface>
    <div class="content">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="flex">
          <img :src="item.cover || src" alt="" />
          <div class="item_1">
            <div class="item_1_1">
              {{ item.title }}
            </div>
            <div class="item_1_2">
              {{ item.total_people }}人已学 &nbsp;&nbsp;&nbsp;&nbsp; 学习时长{{
                item.class_hour
              }}课时
            </div>
          </div>
        </div>
        <div>
          <!-- <div class="item_2">学习进度{{ item.ksSpeed }}%</div> -->
          <div class="item_2"></div>
          <div class="item_3" @click="gostudy(item)">签到学习</div>
        </div>
      </div>
      <div style="height: 500px"></div>
    </div>
    <!-- 弹窗 -->
    <div class="mask1" v-show="flag">
      <div>
        <img class="imgs" src="../../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">学习身份核验</div>
          <div class="content" v-show="num == 1">
            在开始您的学习之前我们将对您进行学习身份核验届时会有核验短信发到您的手机请确认通过
          </div>
          <el-checkbox
            v-show="num == 1"
            style="margin-left: 45px"
            v-model="checked"
            >同意《职培通在线教育云平台学员核验规定》</el-checkbox
          >
          <div class="content1" v-show="num == 2">
            <input
              class="iut"
              v-model="userInfo.phone"
              disabled
              type="text"
              placeholder="手机号"
            />
            <div class="dis">
              <input
                v-model="verify"
                class="iut1"
                type="text"
                placeholder="验证码"
              />
              <div class="btn" v-if="time < 0" @click="getCode">获取验证码</div>
              <div class="btn" v-if="time >= 0">{{ time }} s</div>
            </div>
          </div>
          <div class="content2" v-show="num == 3">
            <img src="../../assets/wc.png" alt="" />
            <div>核验成功</div>
          </div>
          <div class="dian" @click="submit">
            {{
              num == 1
                ? "开始核验"
                : num == 2
                ? "提交核验"
                : num == 3
                ? "去学习"
                : ""
            }}
          </div>
        </div>
        <img class="img" @click="close" src="../../assets/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      src: require("../../assets/mr.jpg"),
      list: [],
      list1: [],
      list2: [],
      active: 1,
      page: 1,
      time: -1,
      verify: "",
      flag: false,
      num: 1,
      checked: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      learning: (state) => state.learning,
    }),
  },
  methods: {
    ...mapActions([
      "getMinePace",
      "getmyClass_x",
      "getsmsVerificationr",
      "getAlibabaMessage_x",
      "getknowledgeList_x",
      "getTodayStudyTime",
      "getFaceRecognition",
    ]),
    //倒计时
    getTime() {
      var tiemr = setInterval(() => {
        if (this.time >= 0) {
          this.time--;
        } else {
          clearInterval(tiemr);
        }
      }, 1000);
    },
    close() {
      this.flag = false;
      this.num = 1;
    },
    //获取验证码
    getCode() {
      this.getAlibabaMessage_x({
        phone: this.userInfo.phone,
      }).then((res) => {
        console.log(res, "res");

        if (res.successCode == 200) {
          this.time = 100;
          this.getTime();
        }
      });
    },
    // 身份核验
    submit() {
      if (this.num == 1) {
        if (this.checked == false) {
          this.$message({
            message: "请勾选协议",
            type: "warning",
          });
        } else {
          ++this.num;
          return;
        }
      }
      if (this.num == 2) {
        if (this.verify == "") {
          this.$message({
            message: "请填写验证码",
            type: "error",
            offset: 80,
          });
          return;
        }
        this.getsmsVerificationr({
          student_id: this.userInfo.id,
          Verify: this.verify,
          phone: this.userInfo.phone,
          username: this.userInfo.username,
          type: 1,
        }).then((res) => {
          if (res.successCode == 200) {
            ++this.num;
            this.$store.commit("SET_LEARNING", 2);
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
          return;
        });
      }
      if (this.num == 3) {
        // this.$router.push("/Layoutstudy/studyLecture");
        this.$router.push("/Layoutstudy/studyVideo");
      }
    },
    // 签到学习
    gostudy(item) {
		console.log(item)
      console.error(item.class_type,item.face);
	  // return
      window.localStorage.setItem("classtype", item.class_type);
	  window.localStorage.setItem("fasetype", item.face);
	  window.localStorage.setItem("classhour", item.class_hour);
	  window.localStorage.setItem("ksSpeed", item.ksSpeed);
      let that = this;
      this.$store.commit("SET_DETAILS", item);
      if (item.face == 1) {
        this.getFaceRecognition({
          student_id: this.userInfo.id,
          genre: "1", //1. 学习核验 2. 考试核验
          class_id: item.id,
        }).then((res) => {
          if (res.errorCode == 399) {
            that.$refs.chind.control();
          } else if (res.errorCode == 201) {
            this.$message({
              message: res.message,
              type: "warning",
            });
          } else {
            this.$store.commit("SET_CLASSID", item.id);
            this.$store.commit("SET_DETAILS", item);
            this.$store.commit("SET_DET", item);
            this.getTodayStudyTime({
              class_id: item.id,
              user_id: this.userInfo.id,
            }).then((res) => {
              if (res.successCode == 200) {
                if (res.errorCode) {
                  that.$message({
                    message: res.message,
                    type: "success",
                    duration: 10000,
                  });
                }
              }
            });
            // let exam = true
            let str = item.start.substring(0, 10).replace(/-/g, "/");
            let exam = false;
            var d1 = Date.parse(str); // 开班时间
            // console.log(d1)
            var d2 = Date.parse("2022/07/11"); // 新逻辑时间
            if (d1 >= d2) {
              exam = true;
            } else {
              exam = false;
            }

            this.$store.commit("SET_exam", exam);
            // // 针对特殊班级特殊人员进行页面无限制任何规则，不记录时间
            // if (
            //   (this.userInfo.phone == 13585805153 && item.id == 1038) ||
            //   (this.userInfo.phone == 13585805153 && item.id == 1508) ||
            //   (this.userInfo.phone == 13585805153 && item.id == 1811)
            // ) {
            //   this.$store.commit("SET_wwy", 3);
            //   if (exam) {
            //     this.$router.push("/Layoutstudy/studyVideoexamine");
            //   } else {
            //     this.$router.push("/Layoutstudy/studyVideoexamine1");
            //   }
            //   return;
            // }
            this.getknowledgeList_x({
              class_id: item.id, //班级id,
              student_id: this.userInfo.id, //学员id
              type: "1", //1. 视频 2. 音频
              page: 1,
              pageSize: 1,
            }).then((res) => {
              if (res.responseBody.knowledge.list.length == 0) {
                // this.$store.commit("SET_ROUTER", "/studyLayout/studyVideo"); // 普通课程
                this.$store.commit("SET_wwy", 1);
                this.$router.push("/Layoutstudy/studyVideo");
              } else {
                let aaa =
                  parseFloat(
                    res.responseBody.knowledge.list[0].video
                  ).toString() == "NaN";
                if (aaa == false) {
                  this.$store.commit("SET_wwy", 2);
                } else {
                  this.$store.commit("SET_wwy", 1);
                }

                let str = item.city_ids.substring(0, 1);
                //参考云课堂逻辑
                if (str == "") {
                  if (this.learning == 1) {
                    this.flag = true;
                  } else {
                    if (aaa == false) {
                      if (exam == true) {
                        this.$router.push("/Layoutstudy/studywyyVideoCopy");
                      } else {
                        this.$router.push("/Layoutstudy/wyystudyVideo");
                      }
                    } else {
                      if (exam == true) {
                        this.$router.push("/Layoutstudy/studyVideoCopy");
                      } else {
                        this.$router.push("/Layoutstudy/studyVideo");
                      }
                    }
                  }
                } else {
                  if (aaa == false) {
                    if (exam == true) {
                      this.$router.push("/Layoutstudy/studywyyVideoCopy");
                    } else {
                      this.$router.push("/Layoutstudy/wyystudyVideo");
                    }
                  } else {
                    if (exam == true) {
                      this.$router.push("/Layoutstudy/studyVideoCopy");
                    } else {
                      this.$router.push("/Layoutstudy/studyVideo");
                    }
                  }
                  // if (str == 2) {
                  //   if (this.learning == 1) {
                  //     this.flag = true;
                  //   } else {
                  //     if (aaa == false) {
                  //       if (exam == true) {
                  //         this.$router.push("/Layoutstudy/studywyyVideoCopy");
                  //       } else {
                  //         this.$router.push("/Layoutstudy/wyystudyVideo");
                  //       }
                  //     } else {
                  //       if (exam == true) {
                  //         this.$router.push("/Layoutstudy/studyVideoCopy");
                  //       } else {
                  //         this.$router.push("/Layoutstudy/studyVideo");
                  //       }
                  //     }
                  //   }
                  // } else {
                  //   this.$store.commit("SET_LEARNING", 2);
                  //   if (aaa == false) {
                  //     if (exam == true) {
                  //       this.$router.push("/Layoutstudy/studywyyVideoCopy");
                  //     } else {
                  //       this.$router.push("/Layoutstudy/wyystudyVideo");
                  //     }
                  //   } else {
                  //     if (exam == true) {
                  //       this.$router.push("/Layoutstudy/studyVideoCopy");
                  //     } else {
                  //       this.$router.push("/Layoutstudy/studyVideo");
                  //     }
                  //   }
                  // }
                }
              }
            });
            // if (item.wyy == 1) {
            //   this.$store.commit("SET_wwy", 2);
            // } else {
            //   this.$store.commit("SET_wwy", 1);
            // }
          }
        });
      } else {
        this.$store.commit("SET_CLASSID", item.id);
        this.$store.commit("SET_DETAILS", item);
        this.$store.commit("SET_DET", item);
        this.getTodayStudyTime({
          class_id: item.id,
          user_id: this.userInfo.id,
        }).then((res) => {
          if (res.successCode == 200) {
            if (res.errorCode) {
              that.$message({
                message: res.message,
                type: "success",
                duration: 10000,
              });
            }
          }
        });
        // let exam = true
        let str = item.start.substring(0, 10).replace(/-/g, "/");
        let exam = false;
        var d1 = Date.parse(str); // 开班时间
        // console.log(d1)
        var d2 = Date.parse("2022/07/11"); // 新逻辑时间
        if (d1 >= d2) {
          exam = true;
        } else {
          exam = false;
        }

        this.$store.commit("SET_exam", exam);
        // // 针对特殊班级特殊人员进行页面无限制任何规则，不记录时间
        // if (
        //   (this.userInfo.phone == 13585805153 && item.id == 1038) ||
        //   (this.userInfo.phone == 13585805153 && item.id == 1508) ||
        //   (this.userInfo.phone == 13585805153 && item.id == 1811)
        // ) {
        //   this.$store.commit("SET_wwy", 3);
        //   if (exam) {
        //     this.$router.push("/Layoutstudy/studyVideoexamine");
        //   } else {
        //     this.$router.push("/Layoutstudy/studyVideoexamine1");
        //   }
        //   return;
        // }
        this.getknowledgeList_x({
          class_id: item.id, //班级id,
          student_id: this.userInfo.id, //学员id
          type: "1", //1. 视频 2. 音频
          page: 1,
          pageSize: 1,
        }).then((res) => {
          if (res.responseBody.knowledge.list.length == 0) {
            // this.$store.commit("SET_ROUTER", "/studyLayout/studyVideo"); // 普通课程
            this.$store.commit("SET_wwy", 1);
            this.$router.push("/Layoutstudy/studyVideo");
          } else {
            let aaa =
              parseFloat(res.responseBody.knowledge.list[0].video).toString() ==
              "NaN";
            if (aaa == false) {
              this.$store.commit("SET_wwy", 2);
            } else {
              this.$store.commit("SET_wwy", 1);
            }

            let str = item.city_ids.substring(0, 1);
            //参考云课堂逻辑
            if (str == "") {
              if (this.learning == 1) {
                this.flag = true;
              } else {
                if (aaa == false) {
                  if (exam == true) {
                    this.$router.push("/Layoutstudy/studywyyVideoCopy");
                  } else {
                    this.$router.push("/Layoutstudy/wyystudyVideo");
                  }
                } else {
                  if (exam == true) {
                    this.$router.push("/Layoutstudy/studyVideoCopy");
                  } else {
                    this.$router.push("/Layoutstudy/studyVideo");
                  }
                }
              }
            } else {
              // if (aaa == false) {
              //   if (exam == true) {
              //     this.$router.push("/Layoutstudy/studywyyVideoCopy");
              //   } else {
              //     this.$router.push("/Layoutstudy/wyystudyVideo");
              //   }
              // } else {
              //   if (exam == true) {
              //     this.$router.push("/Layoutstudy/studyVideoCopy");
              //   } else {
              //     this.$router.push("/Layoutstudy/studyVideo");
              //   }
              // }
              if (str == 2) {
                if (this.learning == 1) {
                  this.flag = true;
                } else {
                  if (aaa == false) {
                    if (exam == true) {
                      this.$router.push("/Layoutstudy/studywyyVideoCopy");
                    } else {
                      this.$router.push("/Layoutstudy/wyystudyVideo");
                    }
                  } else {
                    if (exam == true) {
                      this.$router.push("/Layoutstudy/studyVideoCopy");
                    } else {
                      this.$router.push("/Layoutstudy/studyVideo");
                    }
                  }
                }
              } else {
                this.$store.commit("SET_LEARNING", 2);
                if (aaa == false) {
                  if (exam == true) {
                    this.$router.push("/Layoutstudy/studywyyVideoCopy");
                  } else {
                    this.$router.push("/Layoutstudy/wyystudyVideo");
                  }
                } else {
                  if (exam == true) {
                    this.$router.push("/Layoutstudy/studyVideoCopy");
                  } else {
                    this.$router.push("/Layoutstudy/studyVideo");
                  }
                }
              }
            }
          }
        });
        // if (item.wyy == 1) {
        //   this.$store.commit("SET_wwy", 2);
        // } else {
        //   this.$store.commit("SET_wwy", 1);
        // }
      }
    },
    cut(val) {
      this.active = val;
      if (val == 1) {
        this.list = this.list1;
      } else {
        this.list = this.list2;
      }
    },
    // 列表数据
    getlist() {
      this.getmyClass_x({
        student_id: this.userInfo.id, //学员id
        title: "", //班级名称
        type: "2", //1. 正在学 2.已完成
        form: "1", //1. 显示进度条 2. 不显示
        page: 1,
        pageSize: "100",
      }).then((res) => {
        if (res.code == 200) {
          res.responseBody.list.forEach((e, i) => {
            // e.ksSpeed = +e.ksSpeed.replace("%", "");
            this.list2.push(e);
          });
        }
      });
    },
  },
  mounted() {
    // 列表数据
    this.getmyClass_x({
      student_id: this.userInfo.id, //学员id
      title: "", //班级名称
      type: "1", //1. 正在学 2.已完成
      form: "1", //1. 显示进度条 2. 不显示
      page: 1,
      pageSize: "100",
    }).then((res) => {
      if (res.successCode == 200) {
        let list1 = [];
        res.responseBody.list.forEach((e, i) => {
          // e.ksSpeed = +e.ksSpeed.replace("%", "");
          list1.push(e);
        });
        this.list1 = list1;
        this.list = list1;
        if (res.responseBody.list.length == 15) {
          this.getlist();
        }
      }
    });
    // this.getlist();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.mask1 {
  position: fixed;
  z-index: 99;

  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .imgs {
    display: block;
    width: 250px;
    margin: 0 auto;
    // vertical-align: middle;
  }
  .img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 15px auto;
    cursor: pointer;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 414px;
    height: 377px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      padding-top: 29px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
      span {
        font-size: 18px;
      }
      .span {
        font-weight: 500;
        font-size: 16px;
        color: #000;
      }
    }
    .content {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .content1 {
      margin: 20px 29px;
      .iut {
        outline: none;
        height: 40px;
        line-height: 30px;
        width: 360px;
        font-size: 18px;
        border: 0px none;
        border-bottom: 1px solid #cfcfcf;
      }
      .dis {
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #cfcfcf;
        .btn {
          cursor: pointer;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .iut1 {
        font-size: 18px;
        outline: none;
        height: 40px;
        line-height: 30px;
        border: 0px none;
      }
    }
    .content2 {
      img {
        display: block;
        width: 60px;
        height: 60px;
        margin: 29px auto 18px;
      }
      div {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        text-align: center;
      }
    }
    .dian {
      border-top: 2px solid #eaeaea;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      margin-top: 15px;
      text-align: center;

      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      cursor: pointer;
    }
  }
}
.tab {
  margin-top: 14px;
  height: 92px;
  line-height: 92px;
  background: #ffffff;
  display: flex;
  padding: 0 47px;
  box-sizing: border-box;
  div {
    cursor: pointer;
    position: relative;
    margin-right: 66px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a7a7a7;
  }
  .active {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    &::before {
      position: absolute;
      display: block;
      bottom: 0;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      width: 96px;
      height: 4px;
      background: #137cfb;
    }
  }
}
.content {
  margin-top: 14px;
  background-color: #fff;
  padding: 43px;
  box-sizing: border-box;
  .item {
    margin-bottom: 33px;
    border-bottom: 1px solid #e1e1e1;
    height: 115px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    img {
      width: 140px;
      height: 81px;
    }
    .item_1 {
      margin-left: 24px;
      .item_1_1 {
        width: 306px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7c7c7c;
      }
      .item_1_2 {
        margin-top: 12px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7c7c7c;
      }
    }
    .item_2 {
      text-align: right;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7c7c7c;
    }
    .item_3 {
      cursor: pointer;
      margin-top: 26px;
      width: 100px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #e9f3ff;
      border: 2px solid #137cfb;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #137cfb;
    }
  }
}
</style>
